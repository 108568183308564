<template>
  <div id="reservations-info">
    <b-container fluid>
    <b-row>
      <b-col sm="12" lg="8">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="page-title w-100 d-flex alig-items-center justify-content-between">
              <h4 class="card-title">{{ $t('nav.rooms') }}</h4>
            </div>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col sm="6" v-for="(room,index) in reservstion.rooms" :key="index">
                <div class="rooms-card " @click="goRoomDetails(room)" :style="{backgroundImage: ''}">
                  <div class="img-container w-100 h-100">
                    <img :src="room.image_url || 'https://images.unsplash.com/photo-1611892440504-42a792e24d32?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'" alt="">
                  </div>
                  <div class="info bg-white shadow p-2">
                    <h6 class="font-weight-bold d-flex flex-column align-items-center justify-content-between">
                      {{room.name}}
                      <span class="" style="font-size: 14px ; color: #999999">
                        {{ $t('room-count') }} : {{room.number_of_rooms}}
                      </span>
                    </h6>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
      <b-col cols="4">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col cols="12">
                <img class="img-fluid rounded" src="@/assets/images/avatar-user.jpg" alt="">
              </b-col>
              <b-col cols="12">
                <div class="info text-center">
                  <h6>{{reservstion.full_name}}</h6>
                  <p class="mb-0" style="color: #999999">
                    {{reservstion.email}}
                  </p>
                  <p class="mb-1" style="color: #999999">
                    {{reservstion.phone}}
                  </p>
                </div>
                <ul class="p-0">
                  <li class="d-flex align-items-center justify-content-between py-1 px-2 rounded iq-bg-primary mb-1">
                    <span>
                      {{ $t('country') }} :
                    </span>
                    <span class="font-weight-bold">
                      {{ reservstion.country }}
                    </span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between py-1 px-2 rounded iq-bg-primary mb-1">
                    <span>
                      {{ $t('child') }} :
                    </span>
                    <span class="font-weight-bold">
                      {{ reservstion.children }}
                    </span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between py-1 px-2 rounded iq-bg-primary mb-1">
                    <span>
                      {{ $t('adult') }} :
                    </span>
                    <span class="font-weight-bold">
                      {{ reservstion.adults }}
                    </span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between py-1 px-2 rounded iq-bg-primary mb-1">
                    <span>
                      {{ $t('arrival-time') }} : :
                    </span>
                    <span class="font-weight-bold">
                      {{ reservstion.arrival_time }}
                    </span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between py-1 px-2 rounded iq-bg-primary mb-1">
                    <span>
                      {{ $t('checkin') }} :
                    </span>
                    <span class="font-weight-bold">
                      {{ reservstion.checkin }}
                    </span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between py-1 px-2 rounded iq-bg-primary mb-1">
                    <span>
                      {{ $t('checkout') }} :
                    </span>
                    <span class="font-weight-bold">
                      {{ reservstion.checkout }}
                    </span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between py-1 px-2 rounded iq-bg-primary">
                    <span>
                      {{ $t('price') }} :
                    </span>
                    <span class="font-weight-bold">
                      {{ reservstion.total_price }}
                    </span>
                  </li>
                </ul>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    </b-container>
  </div>
</template>
<script lang="ts">
export default {
  computed: {
  },
  mounted () {
    this.$store.dispatch('getReservationInfo', this.$route.params.id)
  }
}
</script>
<style>

.rooms-card {
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.rooms-card img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.rooms-card .info {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  border-radius: 5px;

}
</style>
